<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="bell" /> Alertas</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row>
                <b-col></b-col>
                <b-col class="mb-3">
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="keyword"
                      placeholder="Buscar"
                      type="text"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                bordered
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(date)="row">
                  <p v-text="fecha(row.item.date)" />
                </template>
                <template #cell(see)="row">
                  <b-button
                    size="sm"
                    pill
                    variant="info"
                    @click="dateItem(row.item)"
                    ><b-icon-eye
                  /></b-button>
                </template>
                <template #cell(action)="row">
                  <b-button
                    size="sm"
                    pill
                    variant="danger"
                    @click="marcar(row.item)"
                    >Observar</b-button
                  >
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal para mostrar información-->
    <div>
      <b-modal
        scrollable
        centered
        class="modal-lg"
        v-model="show"
        title="Información"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <b-alert
            v-if="JSON.stringify(data) != '{}'"
            class="row"
            show
            variant=""
          >
            <div class="col-7">
              <div v-for="(dato, index) in data" :key="index">
                <div class="col" v-if="dato.type == '#'">
                  <b style="display: flex; justify-content: center"
                    >{{ dato.name }}:
                    <a
                      class="mb-3"
                      :href="$urlFirebase + dato.value + '?alt=media'"
                      target="_blank"
                    >
                      <b-icon type="button" icon="link45deg" /></a
                  ></b>

                  <b-overlay
                    :show="showImage"
                    variant="light"
                    :opacity="0"
                    blur="2px"
                    rounded="sm"
                  >
                    <b-img
                      center
                      class="zoom objetfit"
                      :src="$urlFirebase + dato.value + '?alt=media'"
                      alt="No se puede mostrar la imagen"
                    ></b-img>
                  </b-overlay>
                </div>
              </div>
            </div>

            <div class="col-5 mt-3">
              <div v-if="!editForm">
                <div v-for="(dato, index) in data" :key="index">
                  <label v-if="dato.type != '#'"
                    ><b>{{ dato.name }}:</b> {{ dato.value }}</label
                  >
                </div>
              </div>
              <div v-else>
                <div v-for="(dato, index) in dataEdit" :key="index">
                  <div v-if="dato.editable">
                    <label
                      ><b>{{ dato.name }}</b></label
                    >
                    <b-form-input
                      type="text"
                      v-model="dato.value"
                      :value="dato.value"
                    />
                  </div>
                </div>
              </div>
              <b-button
                v-if="!editForm"
                @click="formEdit()"
                variant="primary"
                class="w-100 mt-3"
              >
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon> Editar
                datos
              </b-button>
              <b-button
                @click="formEditAdd()"
                v-else
                variant="warning"
                class="w-100 mt-3"
              >
                Aceptar
              </b-button>
            </div>
          </b-alert>
        </b-container>
        <template #modal-footer>
          <div class="w-100">
            <!--Validación-->
            <b-alert class="mb-1 py-1" show variant="primary">
              <b-form-group
                class="m-0"
                label="Validaciones:"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="checkbox-group-1"
                  v-model="selectedList"
                  :options="dataValidate.events"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-1"
                ></b-form-radio-group>
              </b-form-group>
            </b-alert>
            <button
              type="button"
              @click="send()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2 mt-0"
              :disabled="stateCheck(selectedList)"
            >
              Validar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Modal para observar información-->
    <div>
      <b-modal centered v-model="showObserved" title="Observar">
        <b-container fluid>
          <!--Contenido modal-->
          <div class="form-group">
            <label for="exampleInputEmail1">Comentario</label>
            <b-form-textarea
              class="form-control"
              placeholder="Ingrese comentario"
              v-model="dataObserved.content"
              required
            />
          </div>
        </b-container>
        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="observar()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar observación
            </button>
            <button
              type="button"
              @click="exitObserved()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje alerta envio verificación-->

    <div>
      <b-modal centered v-model="showSend" title="Alerta">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">¿Seguro que desea verificar?</h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="validarItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitSend()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    showImage: false,
    editForm: false,
    showSend: false,
    selectedList: null,
    selected: "",
    keyword: "",
    perPage: 10,
    currentPage: 1,
    dataValidate: {},
    show: false,
    showObserved: false,
    data: {},
    dataEdit: [],
    actions: [],
    dataObserved: {},
    fields: [
      { key: "index", label: "#", sortable: true },
      { key: "name", label: "Tipo" },
      { key: "sender", label: "Autor" },
      { key: "personName", label: "Nombre" },
      { key: "date", label: "Fecha y hora", sortable: true },
      { key: "see", label: "Ver" },
      { key: "action", label: "Acción" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.sender.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.date.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    async sleep(msec) {
      return new Promise((resolve) => setTimeout(resolve, msec));
    },
    async formEditAdd() {
      var edit = {
        id: this.dataValidate.id,
        data: [],
      };
      this.editForm = false;
      for (var i = 0; i < this.dataEdit.length; i++) {
        if (this.dataEdit[i].editable) {
          var obj = {};
          obj.id = this.dataEdit[i].id;
          obj.label = this.dataEdit[i].value;
          edit.data.push(obj);
        }
      }
      //console.log(JSON.stringify(edit, undefined, "\t"));
      await this.$axios
        .post("/alert/edit", edit, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.$message.success(res.data.content);
        })
        .catch((e) => {
          this.$message.error(e.response.data.content);
          console.log(e);
        });
      //Actualizar datos visualizados
      await this.$axios
        .get("/alert/view?id=" + edit.id, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.data = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formEdit() {
      this.editForm = true;
    },
    exitSend() {
      this.showSend = false;
    },
    send() {
      this.showSend = true;
    },
    stateCheck(date) {
      if (date.length == 0) return true;
      else return false;
    },
    fecha(date) {
      return date.substring(0, 10) + " (" + date.substring(11, 16) + ")";
    },
    async validarItem() {
      /*this.selectedList.sort((a, b) => a - b);
      for (var i = 0; i < this.selectedList.length; i++) {
        arreglo.push({ text: "", value: this.selectedList[i] });
      }*/
      this.dataValidate.event = this.selectedList;
      this.dataValidate.actions = null;
      //console.log(JSON.stringify(this.dataValidate, undefined, "\t"));
      await this.$axios
        .post("/alert/validate", this.dataValidate, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.showSend = false;
          this.show = false;
          this.$message.success(res.data.content);
          this.initialize();
        })
        .catch((e) => {
          this.showSend = false;
          this.show = false;
          this.$message.error(e.response.data.content);
          console.log(e);
        });
    },
    exit() {
      this.show = false;
      this.initialize();
    },
    exitObserved() {
      this.showObserved = false;
      this.initialize();
    },
    marcar(data) {
      this.showObserved = true;
      this.dataObserved = {
        id: data.id,
        content: null,
      };
    },
    async observar() {
      await this.$axios
        .post("/alert/observe", this.dataObserved, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.showObserved = false;
          this.$message.success(res.data.content);
          this.initialize();
        })
        .catch((error) => {
          this.showObserved = false;
          console.log(error);
        });
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/alert", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          var cont = 0;
          for (var i in res.data) {
            res.data[i].index = cont + 1;
            aux.push(res.data[i]);
            cont++;
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async dateItem(item) {
      this.showImage = true;
      this.selectedList = [];
      this.data = {};
      this.editForm = false;
      this.show = true;
      await this.$axios
        .get("/alert/view?id=" + item.id, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.data = res.data.data;
          this.dataValidate = res.data;
          this.actions = res.data.actions;
          this.dataEdit = res.data.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      await this.sleep(1000);
      this.showImage = false;
    },
  },
};
</script>
<style scoped>
.card-header {
  background-color: #1d425e;
  color: white;
}
.objetfit {
  width: 100%;
  object-fit: cover;
}
</style>
<style>
.modal-dialog {
  max-width: 800px !important;
}
.zoom {
  transition: transform 0.2s;
}
.zoom:hover {
  transform: scale(1.2);
}
</style>